

















































































































import {
  defineComponent,
} from '@nuxtjs/composition-api';
import { SfImage, SfHeading} from '@storefront-ui/vue';
import SfCarousel from "~/components/organisms/SfCarousel/SfCarousel.vue";
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    SfCarousel,
    SfImage,
    SfHeading,
    LoadWhenVisible,
    NewProducts: () => import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
    BlogPosts: () => import(/* webpackPrefetch: true */ '~/components/BlogPosts.vue'),
  },
  setup() {

  },
});
