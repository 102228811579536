<template>
  <div class="sf-carousel">
    <div class="sf-carousel__wrapper">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides sf-carousel__slides">
            <slot />
          </ul>
        </div>
      </div>
      <div
        :class="{ 'display-none': numberOfPages <= 1 }"
        class="sf-hero__bullets"
      >
        <slot name="bullets" v-bind="{ numberOfPages, page, go }">
          <SfBullets
            :total="numberOfPages"
            :current="page - 1"
            data-testid="hero-bullets"
            @click="go($event)"
          />
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Glide from "@glidejs/glide";
import SfBullets from "@storefront-ui/vue/src/components/atoms/SfBullets/SfBullets.vue";
export default {
  name: "SfCarousel",
  components: {
    SfBullets
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      glide: null,
      defaultSettings: {
        type: "carousel",
        rewind: true,
        perView: 4,
        slidePerPage: true,
        gap: 0,
        breakpoints: {
          1023: {
            perView: 2,
            peek: {
              before: 0,
              after: 50,
            },
          },
        },
      },
    };
  },
  computed: {
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints,
      };
    },
    numberOfPages() {
      return this.$slots.default
        ? this.$slots.default.filter((slot) => slot.tag).length
        : 0;
    },
    page() {
      if (this.glide) {
        return this.glide.index + 1;
      }
      return 1;
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      if (!this.$slots.default) return;
      const glide = new Glide(this.$refs.glide);
      glide.mount();
      this.glide = glide;
    });

    if (this.numberOfPages > 1) {
      this.$nextTick(() => {
        if (!this.$slots.default) return;
        const glide = new Glide(this.$refs.glide, this.mergedOptions);
        glide.mount();
        this.glide = glide;
      });
    }
  },
  methods: {
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case "prev":
          this.glide.go("<");
          break;
        case "next":
          this.glide.go(">");
          break;
        default:
          this.glide.go(`=${direct}`);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.sf-carousel {
  &__wrapper {
    --carousel-width: 100%;
  }
}

.sf-carousel {
  .sf-hero__bullets {
    bottom: 40px;
  }

  .sf-bullet {
    width: 13px;
    height: 13px;
    background: #BBBBBB;
    margin: 0 9px;
    transform: inherit;

    &.is-active {
      background: #F3F6F8;
    }

    &::before {
      box-shadow: none;
    }
  }

  @include for-mobile {
    .sf-hero__bullets {
      display: flex;
      bottom: 20px;
    }
  }
}
</style>
